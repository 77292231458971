.forget-pass {
  font-size: 15px;
  color: #8b8c90;
  margin: 50px 0;
  text-align: center;
  .title {
    font-size: 28px;
    margin-bottom: 0.5rem;
  }
  .wrapper {
    padding: 50px 100px;
    margin: 20px auto !important;
    text-align: center;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  }
}

.login-form .form-group .form-control:focus {
  border: 1px solid #6fda44;
  box-shadow: none;
}

.bredcrump-section {
  background: #6fda44;
}
.bredcrump-section .breadcrumb {
  background: none;
}

.breadcrumb-item.active {
  color: #fff;
  font-weight: 600;
}

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item a {
  color: #000 !important;
}

@media only screen and (max-width: 992px) {
  .wrapper {
    padding: 0 !important;
  }
  .login-form input {
    padding: 0 !important;
    text-indent: 100px;
  }
  .login-form .form-group .login-btn {
    text-indent: 0;
  }
}

@media only screen and (max-width: 767px) {
  .login-form {
    padding: 25px 20px;
  }
}
