.item {
  transition: transform 0.3s ease-in-out;
}

.sliding {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.sliding .item {
  transform: translateX(-20%);
}
