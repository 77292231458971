.banner-account-detection {
  background-size: cover !important;
  height: 299px;
  padding: 175px 100px;
  text-align: center;
  background-repeat: no-repeat;
  position: relative;
  background-position: center center;
  margin-bottom: 78px;
  & .bg-blur {
    background: rgba(0, 0, 0, 0.5);
    padding: 130px 250px;
  }
  h2 {
    color: #fff;
    font-size: 48px;
    font-weight: 700 !important;
    max-width: 935px;
  }
  p {
    font-size: 16px;
    color: #ffffff;
  }
}

.content {
  max-width: 1230px;
  margin: 0 auto;
  &__heading {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: #2a3539;
    margin-bottom: 84px;
  }
  &__title {
    display: flex;
    align-items: flex-end;
    margin-bottom: 60px;
    &-index {
      color: #6fda44;
      font-size: 20px;
      line-height: 22px;
      margin-right: 2px;
    }
    &-text {
      font-size: 28px;
      font-weight: 700;
      line-height: 22px;
      color: #2a3539;
    }
  }
  &__sub-title {
    display: flex;
    align-items: flex-start;
    margin-bottom: 48px;
    &-index {
      color: #6fda44;
      font-size: 18px;
      line-height: 30px;
      margin-right: 2px;
    }
    &-text {
      font-size: 18px;
      line-height: 30px;
      font-weight: 500 !important;
      color: #2a3539;
    }
  }
  &__text {
    font-weight: 500;
    font-size: 18px;
    color: #2a3539;
    margin-bottom: 48px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .banner-account-detection {
    .bg-blur {
      padding: 120px 20px;
    }
  }
  .content {
    max-width: 950px;
  }
}

@media only screen and (max-width: 679px) {
  .content {
    max-width: 400px;
  }
  .banner-account-detection {
    .bg-blur {
      padding-top: 80px;
    }
  }
  .content {
    &__title {
      align-items: flex-start;
    }
  }
}
